const LocationReviewsData = [
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Angel Chavez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ashley H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Darrell Wood",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "George Arias",
        "service_type": "Garage Door Repair",
        "tech_name": "Derrick L.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Paula Wheeler",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Craig W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Amanda Torres",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brandi M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "James Shelton",
        "service_type": "Garage Door Services",
        "tech_name": "Melissa R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Joseph Murray",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Veronica W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Jesus Alexander",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jenny E.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Daniel Turner",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kevin A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Sara Holt MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Yvette Y.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Stephanie Orr",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tanya W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Melissa Clark",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Phillip H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Monica Cooke",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Phillip M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Michael Wilson",
        "service_type": "Garage Door Repair",
        "tech_name": "John H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Paula Sutton",
        "service_type": "Garage Door Installation",
        "tech_name": "Charles J.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Megan Thomas",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melissa P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Julie Edwards",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ross S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Victor Cordova",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Daniel M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Lindsay Gay",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Hannah O.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Amanda Dalton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alexis N.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Angela Kelly",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kyle J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Herbert Cameron",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amanda B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Lee Sharp",
        "service_type": "Garage Door Insulation",
        "tech_name": "Daniel G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Dennis Anderson",
        "service_type": "Garage Door Off Track",
        "tech_name": "David M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Jose Adams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Cody J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Robert Waters",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Patricia M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Lindsey Walsh",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carlos W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.8,
        "customer_name": "Natalie Moran",
        "service_type": "Garage Door Services",
        "tech_name": "Danny O.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Lisa Young MD",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Travis H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Jennifer Evans",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Wesley L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Angelica Hess",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kaitlyn J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Steven Richardson",
        "service_type": "Garage Door Repair",
        "tech_name": "Allison M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Jacob Mcintosh",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Julie D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Nicole Walters",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Juan W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Andrew Brown",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gregory A.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Robin Preston",
        "service_type": "Garage Door Opener",
        "tech_name": "Vicki L.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Charles Tran",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jessica L.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Jorge Singh",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Joseph",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Timothy D.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Kathleen Douglas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Tasha Mason",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Oscar S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Natalie Ramos",
        "service_type": "Garage Door Insulation",
        "tech_name": "Victor T.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Reginald Lopez DVM",
        "service_type": "Garage Door Insulation",
        "tech_name": "Matthew M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Paula Brady",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kenneth C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Terry Chen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kathy P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Thomas Miller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kyle R.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Mathew Woodward",
        "service_type": "Garage Door Installation",
        "tech_name": "Antonio G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Dawn Sanders",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Renee D.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Marie Riley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Taylor D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Ashley Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Harold W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Brett Barnes",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Charles Foley",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Valerie G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Lisa Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Vincent Z.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "William Mclean",
        "service_type": "Garage Door Insulation",
        "tech_name": "Katherine F.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Aaron Robertson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michelle J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Johnathan Gordon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Marcus M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Keith Pratt",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Natalie E.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Tyler Nguyen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Wayne Alexander",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Peter Escobar",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Justin D.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Thomas Allen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Laurie R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Tim Ayala MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James L.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Thomas Herrera",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Monica J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Christopher Gordon",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lauren M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Brandon Walters",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Douglas M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Amy Padilla",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Stephen D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Donald Hogan",
        "service_type": "Garage Door Services",
        "tech_name": "Kurt M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Gary Martinez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tammy M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Mark Odom",
        "service_type": "Garage Door Services",
        "tech_name": "Stephanie F.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Samantha Jimenez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amy W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Yates",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rachael A.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Monica Preston",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jeffrey G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Leah Olson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Charles Harris",
        "service_type": "Garage Door Services",
        "tech_name": "John S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Patricia Reese",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Peter O.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Richard Harrison",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joseph H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Jacob Rush",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Colton P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Sean Mays",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Allen Harris",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christine E.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Tammy Robinson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lisa S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "David Tyler",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kyle C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Julie Wiley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rebecca J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Colin Bautista",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kevin P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "George Conrad",
        "service_type": "Garage Door Repair",
        "tech_name": "Elizabeth H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Jill Deleon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amber R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Jennifer Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daniel A.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Andrew Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cindy L.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Melinda Chambers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Holly W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Samuel Craig",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Thomas G.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Nicole Hale",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tara B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Dr. Elijah Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Samuel C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Matthew Parker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Andrew S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "David Howard",
        "service_type": "Garage Door Repair",
        "tech_name": "Christy W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Caroline Lopez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeremiah B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Corey Davis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kimberly K.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Larry Fletcher",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jessica W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Patricia Wade",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Monica L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Sharon Blackwell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Miguel G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "James Sellers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lindsay V.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Gibson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lindsey G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Laurie Hoffman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Annette H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "James Garcia",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Samantha M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Christopher Nash",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "John P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Katherine Juarez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stephanie J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Sandra Lam",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Courtney M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Aaron Schroeder",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Connor Y.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Albert Zhang",
        "service_type": "Garage Door Repair",
        "tech_name": "Summer R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Thomas Doyle",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brian B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Yvette Delgado",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Hailey C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Joseph Wagner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Eric B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Caitlin Martin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Charles E.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Nicole Todd",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tony S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Katie Shepard",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amber G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Jaime Hall",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Spencer R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Nathan Pham",
        "service_type": "Garage Door Services",
        "tech_name": "Andrew E.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Tabitha Brown",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tiffany M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Laura Pierce",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Dennis H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Cathy Owens",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Scott R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Brooke Coffey",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Oscar W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Sydney Love",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Charles B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Catherine Stevens",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sierra T.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Savannah Martin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Andrea Melendez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher V.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Shannon Mcbride",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Loretta R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Victoria Brewer",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Keith P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Leonard Holmes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shawn C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Christopher Sherman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lauren O.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Courtney Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David A.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Matthew Lara",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Erika C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Tiffany Patterson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kathleen T.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Sara Bond DDS",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lindsey N.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Angela Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Todd M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Jonathan Brown",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alexandra F.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Jose Holland",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jamie D.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Anne Zimmerman",
        "service_type": "Garage Door Installation",
        "tech_name": "Brooke S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Jamie Wright",
        "service_type": "Garage Door Insulation",
        "tech_name": "Matthew T.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Ashley Barron",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Susan T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Natalie Salazar",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anna W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Nicholas Dorsey",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amanda D.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Christina Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Margaret M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "David Shelton",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ian K.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Christopher Ortiz",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jaime J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Joshua Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Taylor R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Joel Robinson",
        "service_type": "Garage Door Services",
        "tech_name": "Stephanie O.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Thomas Romero",
        "service_type": "Garage Door Repair",
        "tech_name": "Jessica J.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Yesenia Gallagher",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jason D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Belinda Green",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Edward P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Daniel Andersen",
        "service_type": "Garage Door Repair",
        "tech_name": "Stanley B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Angela Garcia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cory H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Medina",
        "service_type": "Garage Door Services",
        "tech_name": "Amy P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Anne Sampson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Vanessa F.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Jennifer Miller",
        "service_type": "Garage Door Services",
        "tech_name": "Dana A.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Cindy Diaz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nicholas S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Lisa Foster MD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jose A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Steven Parker",
        "service_type": "Garage Door Services",
        "tech_name": "Stephen G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Dennis Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Katherine F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Daniel Perez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew P.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Heather Webb",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lindsay R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Joseph Henderson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mary S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Christina Vasquez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Carl M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Dustin Yang",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Andrew F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Cody Burton",
        "service_type": "Garage Door Opener",
        "tech_name": "Lawrence S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Denise Gordon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Nicole Vega",
        "service_type": "Garage Door Opener",
        "tech_name": "Sean V.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Gary Arnold",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Dr. James Schroeder",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Sharon Walker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Hailey M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Brett Cooper",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Patty W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Christine Austin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Roy Reyes",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Laura Vargas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Megan C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Amy Mann",
        "service_type": "Garage Door Repair",
        "tech_name": "Jeffery G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Mr. Charles Hernandez MD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Julie K.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Timothy Alexander",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeremy A.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Angela Watkins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kathryn M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Melissa Donaldson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rachael R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Jermaine Shannon",
        "service_type": "Garage Door Installation",
        "tech_name": "Kimberly M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Green",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Ashley Duarte",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kimberly S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Rachael Cannon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christine C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Lisa Powers",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher N.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Megan Cummings",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brian B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Tamara Patel",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joshua Z.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "William Salinas",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Colleen C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Erin Mcfarland",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Curtis S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Valerie Walker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Jeremy Garcia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gregory R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Thomas Bell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ruben A.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Grace Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Hannah J.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Wesley Hartman",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Matthew B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Samuel Peterson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amanda G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Aimee King",
        "service_type": "Garage Door Services",
        "tech_name": "Anthony S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Jessica Mckay",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Joshua Collins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Susan L.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Robert Ellis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephanie S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Jay Thomas",
        "service_type": "Garage Door Opener",
        "tech_name": "Sarah B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Stacy Leonard",
        "service_type": "Garage Door Repair",
        "tech_name": "Dominique A.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Justin Hill",
        "service_type": "Garage Door Off Track",
        "tech_name": "Miguel H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Jennifer Roberts",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kevin P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Virginia Blair",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jessica P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Courtney Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lance B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Scott White",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brittany T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Deborah Hardy",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Scott S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Angela Jenkins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin A.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Angela Harrington",
        "service_type": "Garage Door Off Track",
        "tech_name": "Virginia J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Kristin Robertson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Caitlyn M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Maria Collins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Desiree F.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Heidi Paul",
        "service_type": "Garage Door Opener",
        "tech_name": "Joel M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Shawn Mendoza",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Todd G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Andrew James",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Eric H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Nathan Thomas",
        "service_type": "Garage Door Installation",
        "tech_name": "Sharon R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Kimberly Richardson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jocelyn T.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Steven Barker",
        "service_type": "Garage Door Installation",
        "tech_name": "Alexis C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Kayla Rose",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Margaret M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Kenneth Fisher",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Laurie R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "David Andrade",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brandon A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Mark Bradley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Douglas M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Jennifer Forbes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Ryan Conrad",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sarah P.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Debra Fletcher",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Judy W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Laura Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ryan T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Ms. Maureen Moore",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jeffrey T.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Douglas Lester",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amy F.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Aaron Clark",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ashley K.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Stephen Shaffer",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicole R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Katrina Wise",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Carolyn A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Stephanie Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Shelly J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "William Cole",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Alisha Garcia",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Ebony Farrell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Carla B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Ricky Bell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carrie S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Rodney Bennett",
        "service_type": "Garage Door Installation",
        "tech_name": "Jonathan M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Michael Ramsey",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sharon R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Mario Morris",
        "service_type": "Garage Door Services",
        "tech_name": "Joseph T.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Debbie Campbell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mary R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Paul Winters",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anthony K.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Kayla Reyes",
        "service_type": "Garage Door Repair",
        "tech_name": "Matthew F.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Shelia Larson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mary N.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "William Marsh",
        "service_type": "Garage Door Installation",
        "tech_name": "Hannah G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Phillip Stewart",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Penny M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Jorge Roman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nancy J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Philip Gonzales",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Natalie M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Jeremy Thomas",
        "service_type": "Garage Door Installation",
        "tech_name": "Roger L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Melissa Spence",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kenneth E.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Samuel Marshall",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brian G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Rachel Webb",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Randall M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Anna Robinson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Mary Martinez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michelle M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Nicole Combs",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jo C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "James Martin",
        "service_type": "Garage Door Services",
        "tech_name": "Brandon D.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Dominique Moore",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tamara T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Tabitha Brown",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Anthony F.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Xavier Foster",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sarah J.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Brian Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kyle G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Carrie Villarreal",
        "service_type": "Garage Door Services",
        "tech_name": "Charles W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Ivan Carpenter",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stacey J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Sandra Patel",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kristina B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Danielle Kim",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cameron W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Sandra Travis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lisa P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Alyssa Phillips",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephanie P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Bradley Diaz II",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lisa B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Jaclyn Maxwell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anthony A.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Patricia Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joseph L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Jennifer Lee",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jacqueline B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Laurie Ibarra",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tammy F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Kelly Griffin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brianna G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Lindsey Coffey",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "George S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Christopher Jordan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michelle W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Brittney Rosales",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Maria C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Kevin Bishop",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Timothy R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Daniel Banks",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kaylee M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Kevin Taylor",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mason H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Kenneth Hardin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John T.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Dennis Barrera",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Becky B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Kristin Anderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Joseph Moran",
        "service_type": "Garage Door Services",
        "tech_name": "Daniel T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Michael Mejia",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephanie L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Kevin Barnett PhD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Matthew Ward",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "David Deleon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Nathan Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Michael Reynolds",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Madison D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Scott Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joseph K.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Patrick Hansen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Steven Wilson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Margaret G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Linda Mueller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Katie W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Tyler Ramsey",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kimberly S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Amanda Henry",
        "service_type": "Garage Door Repair",
        "tech_name": "Stephanie R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Richard Flores",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kathleen H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Adam Bryant",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Robert Hughes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joshua B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Billy Stewart",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Tracy Morse",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "April R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Tammy Sharp",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kimberly F.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Nicholas Morgan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael L.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Melissa Walker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Leah K.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Cheryl Santos",
        "service_type": "Garage Door Installation",
        "tech_name": "Luis S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Jason Jackson",
        "service_type": "Garage Door Installation",
        "tech_name": "Darlene W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Jeremy Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amy R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Julie Carr",
        "service_type": "Garage Door Installation",
        "tech_name": "Henry B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Monica Willis MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Russell S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Madison Douglas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alexander C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Benjamin Collins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Miranda W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Ethan Christian",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alec P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Jose Gordon",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Thomas M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Tate",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kayla W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Jasmine Thomas",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Victoria V.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Mitchell Wilson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Erin M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Greg Ball",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alexander H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "James Ryan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jacqueline C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Diane Lee",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cody S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Mrs. Michelle Whitaker",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Russell T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Diana Clark",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Raymond E.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Donna Green",
        "service_type": "Garage Door Services",
        "tech_name": "Dylan F.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Steven Shaw",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Susan S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Victoria Cooper",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kylie O.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Brittany Austin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Andrew Odom Jr.",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Michael Frey",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kevin L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Vincent Cox",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jon M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Sarah Nunez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Natalie M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Margaret Horn",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lindsay R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Kristi Rich",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Johnathan C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Amanda Miller",
        "service_type": "Garage Door Services",
        "tech_name": "Jon R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Andrew Roberts",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kyle M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "John Manning",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Thomas C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Jeremy Melendez",
        "service_type": "Garage Door Opener",
        "tech_name": "Michele G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Melinda Lopez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brian K.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Antonio Velasquez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Elaine J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Crystal Simpson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kelsey N.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "David Serrano",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Christopher Jackson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Daniel W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Carla Hill",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gary B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "James Lawrence",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rodney K.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Christopher Martin",
        "service_type": "Garage Door Services",
        "tech_name": "Wesley M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Debra Ho",
        "service_type": "Garage Door Services",
        "tech_name": "James L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Laura Silva",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michelle S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Hutchinson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Maria L.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Douglas Roberts",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Krista P.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Laura Adams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Marilyn Patterson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer E.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Diana Richard",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "John S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Knight",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lori W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Mrs. Vanessa Mcclain",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Katrina C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Jennifer Rivera",
        "service_type": "Garage Door Installation",
        "tech_name": "Christine M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Gloria Singleton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nancy T.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Tonya Carr",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stephanie M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Andrea Griffin",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Anthony Smith DDS",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Justin Camacho",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Steven S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Seth Kennedy",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kathryn P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Nicholas Hernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Natalie R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Kelly Castro",
        "service_type": "Garage Door Installation",
        "tech_name": "James H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Patricia Hudson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Karen G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Emily Mitchell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Martin S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Suzanne Lopez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Teresa R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Steven Hill",
        "service_type": "Garage Door Repair",
        "tech_name": "Susan W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Alexander Sanchez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Renee G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Matthew Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Steven B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Danielle Fowler",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Edward R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Heather Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Caleb H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Heather Hopkins",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Edward P.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Mary Barnes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sarah D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Robert Roberts",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Melissa H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Jennifer Leonard DDS",
        "service_type": "Garage Door Installation",
        "tech_name": "Paula F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Ryan Wells",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Valerie Griffin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Austin Gonzalez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Vanessa C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Amber Hickman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Dave Davis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Peter J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Calvin Wise",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lindsay S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Bailey Maldonado",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Todd G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Cody Harding",
        "service_type": "Garage Door Off Track",
        "tech_name": "Destiny G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Michael Collins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Dawn H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Robin Pham",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jimmy B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Patricia Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Corey D.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "William Tucker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Laura L.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Crystal Lawrence",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Clinton M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Christine Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Donna M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Jim Holmes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Andrea C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Christopher Kim",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Janice S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Phillip Williams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Anna T.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Trujillo",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Roger S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Brian Byrd",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Matthew D.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Julie Schultz",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tyler A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Wanda Hess",
        "service_type": "Garage Door Repair",
        "tech_name": "Paul H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Taylor Bartlett",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ann G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Ryan Wyatt",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Zachary C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Brian Chan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jessica P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Mrs. Tiffany Waters MD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alex M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Snow",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Katelyn W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Daniel Griffin",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Amanda Alvarez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cynthia M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Shannon Lee",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Anna P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Larry Jensen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicole C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "John Scott",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ricky G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Joseph Fowler",
        "service_type": "Garage Door Opener",
        "tech_name": "Debbie J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Dawn Greene",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Marcus T.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Dylan Drake",
        "service_type": "Garage Door Repair",
        "tech_name": "Rebecca K.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Kathleen Bell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Logan Acosta",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Monica B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Michael Bell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Travis M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Melissa Hill",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Teresa Riley",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lauren S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Anita Robbins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Christina Hartman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bobby W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Shelly Downs",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Donna P.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Amy Orozco",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kayla H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Ashley Burgess",
        "service_type": "Garage Door Repair",
        "tech_name": "Sean S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Jacob Phillips",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Travis M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Cody Jackson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Sharon Chapman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sue C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Walter Black",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melinda G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Corey Allen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sabrina G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Mark Miller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kenneth B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Sarah Raymond",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Erik T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Alec Johns",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Madeline Petersen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brandon J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Gary Sullivan",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Christopher Riley",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Janice B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Cindy Harris",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph R.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "James Vasquez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Billy S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Michelle Taylor",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer A.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Jill Rangel",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Ashley Cobb",
        "service_type": "Garage Door Opener",
        "tech_name": "Sara W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Christopher Henson",
        "service_type": "Garage Door Opener",
        "tech_name": "Janet F.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Kimberly Casey",
        "service_type": "Garage Door Services",
        "tech_name": "Katie H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Matthew Anderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alexandra P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Margaret Chaney",
        "service_type": "Garage Door Repair",
        "tech_name": "Diana B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Zachary Lin",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Geoffrey T.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Marissa Leonard",
        "service_type": "Garage Door Opener",
        "tech_name": "Karen G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Nancy Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Joanna Gray",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kevin M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Theresa Murray",
        "service_type": "Garage Door Repair",
        "tech_name": "Julia R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Chad Simpson",
        "service_type": "Garage Door Repair",
        "tech_name": "Jackson C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Michelle Burns",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Angela P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Betty Marshall",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Yvonne B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Anna Quinn",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Patrick R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Robert Mcdonald",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Donna H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Stacey Perez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brianna R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Ernest Ellis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jason M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Todd Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Barbara H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Matthew Hall",
        "service_type": "Garage Door Services",
        "tech_name": "Lori L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Tammy Clark",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kelly H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Jennifer Robinson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Robert Rodriguez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Keith H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Nathan Valencia",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mandy C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Janice Johnston",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James D.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Ashley Roberts",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joseph B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Michael Davis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lynn M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Jackson Bryant",
        "service_type": "Garage Door Services",
        "tech_name": "Lauren B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Teresa Reyes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tamara M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Mr. Adam Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David D.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Tracy Deleon",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brian J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Julian Foster",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bianca W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Stacy Craig",
        "service_type": "Garage Door Repair",
        "tech_name": "Todd W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Keith Faulkner",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeffrey W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Charlotte Velez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Shannon Palmer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Caitlyn N.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Andrew Chung",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Douglas Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tanya W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Michael Kennedy",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lauren C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Alyssa Martin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Melissa B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Lauren Lawson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Dalton O.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Andre Moore",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brett M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Ashley Zuniga",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Steven B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Zachary Sherman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin A.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Kimberly Fisher",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kimberly C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Kevin Daniels",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kelly R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Melanie Rodgers",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Aaron S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Erica Brooks",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian J.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Michael Suarez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sarah F.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Kendra Merritt",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Wendy F.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Daisy Campbell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kathleen E.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Shelby Murphy",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Joshua Montoya",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "William W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "John Bush",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sean M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Russell Carter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rebecca B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Emily Ray",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Brady Mahoney",
        "service_type": "Garage Door Installation",
        "tech_name": "Aimee H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Tracy Pena",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Crystal P.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Thomas White",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard O.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Maria Murphy",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lisa P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Casey Allen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert R.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Mrs. Mia Castillo",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Julie S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Daniel Pacheco",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Janice S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Mrs. Kristina Porter PhD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Kayla Avery",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amanda H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Khan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Cody S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Gregory Robinson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Matthew M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "April Andrews",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lucas M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Jennifer Baker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Casey M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Shane Nguyen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Ashley Kaufman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brenda R.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Micheal Knox",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ryan B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Michael Carroll",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "David Holland",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Priscilla Scott MD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stanley C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Teresa Marquez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ashley O.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Erica Anderson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joseph C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Hardin MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Maria A.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Pam Davis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeffery R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Ariel Walker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrew N.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Michelle Crawford",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Devin C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Robin Russell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brandon M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Mrs. Mary Mcpherson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Angela S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Tapia",
        "service_type": "Garage Door Installation",
        "tech_name": "Caleb T.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Gibbs",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "David B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Krista Barnes",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Leonard M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Kyle Barnes",
        "service_type": "Garage Door Repair",
        "tech_name": "Nichole O.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Janice Fitzpatrick",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Elizabeth A.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Rachael Butler",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Allison M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Alexander Jackson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ethan W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Kenneth Robertson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Adam A.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Brian Torres",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Daniel B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Jeremy Scott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Jared Fuller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jose J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Robert Turner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John T.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Daniel Moon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sandy H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Matthew Lopez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lynn H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Kenneth Calhoun",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Maurice Coleman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Richard S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Denise Morgan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James O.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Samantha Wall",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kimberly E.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Ronald Scott",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kristin R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Steve Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anthony H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Nicholas Noble",
        "service_type": "Garage Door Services",
        "tech_name": "Jamie O.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Jonathan Hoffman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jonathan N.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Timothy Reyes",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tiffany W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Andrew Wilson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tiffany Y.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Kristy Best",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Carolyn B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Pamela Gonzalez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Maureen T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Crystal Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Kristen K.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Beverly Welch",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Mike Diaz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ryan H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Mary Sharp",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kim R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Kimberly Short",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Deborah W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Michelle Gray",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Austin P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Juan Little",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Derrick S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Lori Olson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robyn M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Janice Sanchez",
        "service_type": "Garage Door Services",
        "tech_name": "Jim S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "David Olson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mary R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "John Cruz",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Matthew R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Sarah Harris",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Debra W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Nicholas Lee",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Donald M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Matthew Sanders",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Thomas W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Joel Schultz",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kyle G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Steven Ferguson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Patricia H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Kelsey Baker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lori M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Matthew Mclean",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Daniel H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Sarah Davidson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kristen D.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Travis Lamb",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph N.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Michael Callahan",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeffrey M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Autumn Murphy",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melanie P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Terri Larson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cassandra H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Tracy Carroll",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gary H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Jennifer Norton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sandra M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Jeremy Owen Jr.",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tiffany F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Tamara Cox",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Tina Scott",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robyn M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Rodriguez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephen L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Dylan Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ryan C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Sabrina Beck",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jody D.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Robert Schroeder",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stacey L.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Katherine Santana",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jennifer F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Karen Williamson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joseph B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Michael Bennett",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joshua C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Aaron Castro",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jorge S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Mitchell Cruz",
        "service_type": "Garage Door Repair",
        "tech_name": "Craig A.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Jill Trujillo",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kathleen R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Paul Thompson MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Traci H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Anthony Miller",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Elizabeth M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Alexander Davis DDS",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cory P.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Dr. Tracy Anderson",
        "service_type": "Garage Door Opener",
        "tech_name": "Gregory R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Tanya Wallace",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jose L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "John Pierce",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Debra N.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Brian Long",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brian M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Troy Peterson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Allen Lewis",
        "service_type": "Garage Door Repair",
        "tech_name": "Daniel L.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Rebecca Cobb",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kathryn W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Paul Lynch",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sue F.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Angela Reese",
        "service_type": "Garage Door Installation",
        "tech_name": "Kelsey P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Shelby Barnes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lauren B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Dawn Anderson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tamara W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Colleen Stanton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ashley D.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Alyssa Terry",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Stuart G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "William Mckenzie",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melissa B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Kristy Garcia",
        "service_type": "Garage Door Opener",
        "tech_name": "Amy L.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Amanda Rosales",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Philip M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Mark Wiggins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Colleen C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Leah Casey",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Matthew C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Brianna Obrien",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nicole B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Gregory Williams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kevin P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Joseph Norris",
        "service_type": "Garage Door Installation",
        "tech_name": "William F.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Brittany Steele",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Tiffany Frye",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shelley V.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Cynthia Rosales",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Richard L.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "James Curtis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bobby A.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Zachary Baker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Gregory Matthews",
        "service_type": "Garage Door Services",
        "tech_name": "Paul Y.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Dennis Collins",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Frank C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Pamela Allen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Travis H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Patricia Morrow",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Brent Frye",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rebecca R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Dennis Robinson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gregory M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Angela Kennedy",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Erika J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Stacie Combs",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Wesley C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Sarah Bender",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Fernando H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Sheri Thompson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Autumn G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Michelle Park",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Paul G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "William Sharp",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Elizabeth A.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Christine Brown",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kevin R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Diana Ellis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Steven M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Mr. Gabriel Mora",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Holly R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Megan Lyons",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Natasha B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Jacob Murphy",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephanie H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Peter Wells",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Karla M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Douglas Carroll",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mark M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Sean Clayton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ryan P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Joshua Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rhonda F.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Timothy Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Troy P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Tammy Rodriguez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cindy M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Kelly Simpson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sandra S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Kelly David",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Jason Griffin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Crystal B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "David Mueller",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Carla P.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Cynthia Stevenson",
        "service_type": "Garage Door Opener",
        "tech_name": "Ryan T.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Danielle Estrada",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lindsey G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Tristan Wiley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jason G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Mark Roberts",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Pamela K.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Scott Shields",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeffrey C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Alexander Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Alfred F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Micheal Morgan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Austin R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Stanley Jackson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Wesley Zimmerman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Michael Holland",
        "service_type": "Garage Door Services",
        "tech_name": "Sherri B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Elizabeth James",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rebecca F.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Emma Harper",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Martin B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Katherine Rodriguez",
        "service_type": "Garage Door Opener",
        "tech_name": "Craig C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Brandi Montgomery",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kelsey O.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "David Diaz",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Matthew R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Andrea Larsen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Carlos S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Audrey Stephens",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeffrey K.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Sandra Pennington",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Jose Anderson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Katherine W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Sarah Lutz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Steven W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nicholas M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Ann Castro",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer R.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "James Adams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rhonda E.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Phillip Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brandon R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Lindsay Hall",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Heather P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Leonard Brock",
        "service_type": "Garage Door Opener",
        "tech_name": "Javier R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Leah Vasquez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Janet P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Kenneth Hill",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sarah M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Valencia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kristy C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Jaclyn Berry",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tiffany R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Lori Roth",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kimberly A.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Kevin Buchanan",
        "service_type": "Garage Door Installation",
        "tech_name": "Jessica C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Russell Vaughn",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Alice T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Mikayla Robinson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Jordan Warren",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeremiah G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Tanya Davis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Austin H.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Tammy Erickson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mark M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Mr. Martin Gonzalez",
        "service_type": "Garage Door Installation",
        "tech_name": "David S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Michael Melendez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sarah S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Bryan Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Stephanie M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Nina Moran",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Donald H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Brittney Hill",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David E.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "William Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Samantha R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Connie Orr",
        "service_type": "Garage Door Opener",
        "tech_name": "Brandon S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Angela Fisher",
        "service_type": "Garage Door Repair",
        "tech_name": "Heidi H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Travis Shannon",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Johnny W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Joseph Maddox",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sheila Y.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Dale Rosario",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Elizabeth S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Gabriel Lee",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Diana M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Danielle Robinson",
        "service_type": "Garage Door Repair",
        "tech_name": "Spencer W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Stacey Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ashley H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Cynthia Acevedo",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Corey P.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Jason Diaz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Bryce P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Lisa Elliott",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ryan W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Daniel Moran",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Eric P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Emily Martin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nicholas L.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Barry Olson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrew V.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Laura Hall",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Emma Y.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "John Lee",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Spencer J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Valerie Willis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Denise C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Jared Castro",
        "service_type": "Garage Door Installation",
        "tech_name": "Roy C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Allison Lopez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Johnny L.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Robert Jones",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ashley C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Alexandria Gordon",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stacy L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Jessica Olsen",
        "service_type": "Garage Door Installation",
        "tech_name": "Walter T.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Katherine Schneider",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Melanie Brooks",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kenneth D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Paul Walker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Charles V.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Jerry Walker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Denise D.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Devin Robinson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Robert Reed",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Darryl B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Laura Taylor",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Andrew P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Andrea Lane",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stacy F.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Christopher Shaw",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sherri B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer P.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Kristen Strickland",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sean G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Matthew Doyle",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Arthur B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Lisa Young",
        "service_type": "Garage Door Installation",
        "tech_name": "Vicki C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Angela Reyes",
        "service_type": "Garage Door Repair",
        "tech_name": "Samuel H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Gregory Arroyo",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Connie J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Rachael Kelly",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Theresa P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Micheal Roberts",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Peggy C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Michael Roberts",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Richard K.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "George Rasmussen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Steven G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Melissa Arnold",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jesse S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Douglas Henry",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Clifford V.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Scott Jackson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sarah M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Adam Dorsey",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jared C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Kevin Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Karen S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Jennifer Davidson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Emily J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Destiny Hughes",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nichole H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Benjamin Baldwin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Willie G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Julie Davis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stephanie N.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Brian Zimmerman",
        "service_type": "Garage Door Opener",
        "tech_name": "Marie L.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Kevin Chapman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Karen M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Robert Mccarty",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Melissa L.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Cory Rodriguez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert E.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Amanda Gonzalez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kevin B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Evan Burns",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Thomas M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Miguel Cantu",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Angelica R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Audrey Wright",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Karen W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Christina Elliott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jonathan H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Lori Pierce",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Pam Murphy",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Gloria B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Rodney Atkinson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amber W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Christopher Trujillo",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mason B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Jason Collins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Debra B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Jack Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jimmy S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Crystal Young",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Veronica G.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Victoria Walker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michele S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Renee Castro",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stephanie M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Blackwell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joseph C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Alexandra Cervantes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Michael Gonzales",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "George M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Amanda Perry",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jason W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Victoria Guerrero",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Albert J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Kaitlyn Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Catherine G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Jeffery Hodge",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Steven B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Danny Mathis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bryan F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Kristen Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Felicia L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Samantha Castro",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Mary Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Hunter J.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Scott Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Edward H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Scott Wallace",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Emily D.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Michelle Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Eric B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Matthew Reid",
        "service_type": "Garage Door Repair",
        "tech_name": "Thomas M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Matthew Jones",
        "service_type": "Garage Door Installation",
        "tech_name": "Meghan B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Megan Lee",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anne I.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Andrew Chandler",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Adam J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Andrew Evans",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kathleen A.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Frank Davis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Johnny Thompson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kevin D.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "James Campbell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Kathryn Patel",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brianna R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Douglas Banks",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tina S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Tracy Hardy",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kelly Z.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Wesley Sandoval",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Elizabeth R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Michelle Coleman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "John Scott",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Katie L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "David Gonzalez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Valerie P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Aaron Maldonado",
        "service_type": "Garage Door Opener",
        "tech_name": "Thomas D.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Benjamin Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nathan P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Brittany Merritt",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Jack Green",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kyle F.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Rhonda Middleton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Monica J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Morgan Leon",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Allen L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Nichole Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Victor S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Edward Knapp",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Paul R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Aaron Santiago",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Edward Little",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Emily B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Michael Hebert",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tammy H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Kristen Rodriguez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Helen M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Amber Winters",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jon V.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Kenneth Blake",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James N.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Matthew Mccall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amy S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Brett Houston",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jessica R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Kimberly Marshall",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeremy G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Sabrina Zamora",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Angelica B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Nancy Schroeder",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeffrey B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Hannah Clark",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kyle W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Erin Price",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ronald P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Nicholas Garrett",
        "service_type": "Garage Door Repair",
        "tech_name": "Keith E.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Rojas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Richard G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Lauren Silva",
        "service_type": "Garage Door Opener",
        "tech_name": "Brenda H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Kayla Wilson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Maria W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Natalie Patrick",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kelly P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Alicia Richardson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kathy R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Paul Gallegos",
        "service_type": "Garage Door Installation",
        "tech_name": "Brett M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Richard Howard",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ryan M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Misty Collins",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Austin Miller",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Heather H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Dylan Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bethany C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Jennifer Martin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Randy M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Stacy Reynolds",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Christina Jacobs MD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Aaron V.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "William Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ryan R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Danielle Bates",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William N.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Nancy Galvan",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David A.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Andrea Bowers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Kelly Armstrong",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Scott F.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Charlene Wyatt",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Megan G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Christopher Huff",
        "service_type": "Garage Door Insulation",
        "tech_name": "Chad K.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Wendy Thomas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robert C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Linda Sheppard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joseph C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Sherry Mcmahon",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Victoria M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Alan Barrett",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Justin S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Shawn Garcia DVM",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lauren M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Michael Welch",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sheena J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Dr. Joe Ramirez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Charlotte A.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Lawrence Williams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Paige E.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Zachary Santos",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brianna W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Michelle Dodson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bernard S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Jacob Rivers",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Andrea H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Alan Joseph",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dylan M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Robert Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anthony B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Kimberly Knapp",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristie B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Robert King",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Audrey B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Jeremiah Coffey",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Katelyn M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Erin Barajas",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert O.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Michael Zhang",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kelly A.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Ashley Ortiz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amanda J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Robert Harris",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ellen M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "James Lucas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Rachel Santiago",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ruben P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Debra Larson",
        "service_type": "Garage Door Repair",
        "tech_name": "Kelsey A.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Laura Golden",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Adam N.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Fred Austin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeremy S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Mark Cobb",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Maureen W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Raymond Webb",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Carrie M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Jessica Stone",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Sarah Peterson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Regina V.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Micheal Nguyen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Hannah C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Corey Taylor",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael P.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Lauren Powell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jill E.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Brenda Moore",
        "service_type": "Garage Door Off Track",
        "tech_name": "Diane F.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "David Garcia",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Erica Lozano",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sean P.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Jamie Gordon",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gregory G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Christopher Edwards",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Derrick H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Heather Clark",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Mary Dennis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brian G.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Brent Nelson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "James Andersen DVM",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jo M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Anne Beard",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Paul R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Gary Thompson",
        "service_type": "Garage Door Opener",
        "tech_name": "Shelia F.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Susan Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rachael B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Brandon Kim",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeremiah W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Martin Roberson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christopher B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Douglas Walls",
        "service_type": "Garage Door Repair",
        "tech_name": "Thomas M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Meagan Nelson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Scott B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Katherine Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sean W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Susan Burke",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Rachel H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Aaron Mcdowell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mark F.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Lisa Daugherty",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brian L.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Ryan Wright",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Joseph Ellis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kathryn M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Wanda Bailey",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kari T.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Ashley Roberts",
        "service_type": "Garage Door Repair",
        "tech_name": "Joseph H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Danielle Boyle",
        "service_type": "Garage Door Repair",
        "tech_name": "Hannah B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Michelle Lewis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Billy J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Amanda Anderson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Angela T.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Taylor Luna",
        "service_type": "Garage Door Services",
        "tech_name": "Jose D.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Blake Booth",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alexis M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Joshua Chen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sara M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Sheila Oliver",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Darren J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Lorraine Allison",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Michelle Hatfield",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Maria R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Tammy Weaver",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Dr. Jermaine Hall MD",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Matthew G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Nicholas Campos",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Alexis Woodard",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kelsey D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Robert Davis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Allison F.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Nicholas Murray",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Laura N.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Ryan Hernandez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Dorothy S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Melissa Powers",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Juan R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Amanda Middleton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Michael Clements",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jason A.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Frances Rowe",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jose R.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Sandra Lloyd",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Diane W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Mary Wilson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Dakota Munoz",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christy B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Ronald Barnett",
        "service_type": "Garage Door Services",
        "tech_name": "Sabrina W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Maria Beasley",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Debra Spencer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Leah Barrett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Edward C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Kenneth Nguyen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Carolyn P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "James Chavez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Craig P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Richard Reid",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeremy M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Bethany Houston",
        "service_type": "Garage Door Insulation",
        "tech_name": "James C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Jasmine Eaton",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bryan C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Steven Stevens",
        "service_type": "Garage Door Opener",
        "tech_name": "Misty B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Jermaine Turner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sarah H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Mr. Michael Leonard",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Vanessa D.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Tracy Singh",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Natalie T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Robert Day",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kelly M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Katherine Brown",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amy M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Mrs. Heidi Perkins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mark T.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Bryan Morris",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Claire Lee",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jose S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Danielle Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Steven M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Amy Rangel MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Judy V.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Mark Lynch",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Thomas S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Yvette Romero",
        "service_type": "Garage Door Repair",
        "tech_name": "Brittany C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Joel Porter",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gwendolyn L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Adam Carpenter",
        "service_type": "Garage Door Opener",
        "tech_name": "James S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Gerald Matthews",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Sean Mckenzie",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mindy M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Tiffany Patterson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ellen W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Shirley Osborne",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nathaniel H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Melissa Burnett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michelle T.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Benjamin Mckinney",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Patricia D.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Douglas Nash",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary D.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Sandra Friedman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jack T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Michelle Perry",
        "service_type": "Garage Door Installation",
        "tech_name": "Amanda C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Christopher Hernandez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Vickie F.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Gerald Gross",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dennis A.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Christopher Garza",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Haley B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Stephen Crawford",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Brent Mahoney",
        "service_type": "Garage Door Installation",
        "tech_name": "Amanda P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Timothy Christian",
        "service_type": "Garage Door Opener",
        "tech_name": "Cheryl D.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Hernandez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicole A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Dennis Shannon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Courtney Moore",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jay W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Heather Cannon",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Raymond M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Teresa Rodriguez DVM",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Deborah Z.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Mercedes Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cheryl S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Chelsea Blair",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jacob L.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Jonathan Hernandez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Laurie D.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Kayla Shaw",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Marvin J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Cody Brewer DVM",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Carol H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "April Nguyen",
        "service_type": "Garage Door Services",
        "tech_name": "Derrick M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Raymond Freeman",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lauren H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Sabrina Anderson",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Charles Gregory",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brooke V.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Bruce Young",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Renee Ramsey",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alexander B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Melissa Stafford DDS",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jack G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Leah Dominguez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Frederick M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Jody Lee",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gabriella G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Susan Hensley",
        "service_type": "Garage Door Services",
        "tech_name": "Rhonda S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Sara Estrada",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael L.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Michael Durham",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amanda B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Jerry Keith",
        "service_type": "Garage Door Installation",
        "tech_name": "Jodi D.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Travis Casey",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Danielle W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Brian Robinson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Todd P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Ashley Ochoa",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Andrew R.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Theodore Gray",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael A.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Deborah Gardner",
        "service_type": "Garage Door Opener",
        "tech_name": "Amy K.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Andrew Watson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Suzanne W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "John Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert A.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Curtis Robinson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sarah Z.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Michael Ho",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gabriel H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Barbara Patel",
        "service_type": "Garage Door Services",
        "tech_name": "Benjamin M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Breanna Garcia",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shawn S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Ethan West",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Erin H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Jennifer Aguilar",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James D.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Amy Davidson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christina S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Paul Hall",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mary C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Daniel Ortiz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Desiree D.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Ariana Waller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joseph F.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Joseph Sexton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Austin A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Kim Spears",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John L.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Melissa Brown",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Amanda Wallace",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Danny J.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Alexander Kent",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Maria W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Sherry Butler",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Elizabeth F.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Peter Quinn",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Laura N.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Lauren Stephenson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sarah L.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "David Quinn",
        "service_type": "Garage Door Services",
        "tech_name": "Anthony R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Brian Newton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Barbara C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Christopher Fleming",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christine M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Ryan Stuart",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amber G.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Amber Robinson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Autumn T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Hendrix",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kevin K.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Katherine Graham",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dana D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Mr. Timothy Dean",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Elizabeth G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Kelly Hartman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ethan V.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Angela Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Leonard A.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Gregory Noble",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Haley R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Allison Morgan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Benjamin J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Allison Sandoval",
        "service_type": "Garage Door Installation",
        "tech_name": "Calvin C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "David Coleman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Elizabeth R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Tami Barrera",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicole W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Cynthia Pittman",
        "service_type": "Garage Door Opener",
        "tech_name": "Jose R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Gina Lane",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Nicolas Harrison",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brian M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Lisa Villa",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jon F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Betty Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Edward M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Joan Graves",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Dawn M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "James King",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Rebecca S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Monica Munoz",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Debra G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Roger Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Karen S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Cindy Jordan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dawn J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Karen Alexander",
        "service_type": "Garage Door Services",
        "tech_name": "Stephanie S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Theresa Scott",
        "service_type": "Garage Door Installation",
        "tech_name": "Alan R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Mark Dunlap",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nicole L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Tyler Brown",
        "service_type": "Garage Door Opener",
        "tech_name": "Jesse J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Hayden Lamb",
        "service_type": "Garage Door Repair",
        "tech_name": "Ashley G.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Mary Allison",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeffrey H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Kimberly Mckinney",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeffrey H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Dwayne Oconnor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ashley G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Patrick Wong Jr.",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shannon T.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Steven Berry",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Amanda Foster",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Bradley Russell",
        "service_type": "Garage Door Off Track",
        "tech_name": "James S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Eric Sanders",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Marcus M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Daniel White",
        "service_type": "Garage Door Installation",
        "tech_name": "Kimberly J.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Jason Chavez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amber M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Crystal Torres",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Claudia H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Mario Nichols",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Steven G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Ronald Garcia",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brian K.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "James Simpson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Matthew L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "James Simmons",
        "service_type": "Garage Door Installation",
        "tech_name": "Brandi S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Sherry Robertson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sara W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Christopher Ford",
        "service_type": "Garage Door Installation",
        "tech_name": "Paige M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Jesse Peterson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Linda P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Brandon Mays",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Scott V.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Cheyenne West",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Warren M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Lindsey Walsh",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Krystal Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michele D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Janet Hall",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Jennifer Knight",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Charles V.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Monica Miles",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christine M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Emily Campos",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Eric A.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Allison Stark",
        "service_type": "Garage Door Services",
        "tech_name": "Angela M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Kristin Boyd",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Megan W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Jessica Cooper",
        "service_type": "Garage Door Installation",
        "tech_name": "Angela M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Kimberly Barnes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tammy C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Joe Cooper",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amber B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Margaret Reed",
        "service_type": "Garage Door Repair",
        "tech_name": "Gail B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Lisa Lee",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Laura Mullen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Susan C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Trevor Archer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anthony R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Dustin Burns",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Adam R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Edward Zimmerman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brandon W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Robyn Allen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Hernandez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alicia T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Laura Harris",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Evelyn M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Carrie Whitaker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stephanie R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Laura Hunter",
        "service_type": "Garage Door Services",
        "tech_name": "Brenda W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Jessica Thomas",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Meghan C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Jonathan Mullins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joshua C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Dawn Lopez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Frederick R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Renee Roberts",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amanda F.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Alan Manning",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sharon G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Erin Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Larry C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Victor Beasley",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Krystal W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Jessica Burch",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Richard C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Victor Hill",
        "service_type": "Garage Door Off Track",
        "tech_name": "Patrick H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Eric Thomas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Chad E.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Steven Pena",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sue B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Chris Ball",
        "service_type": "Garage Door Insulation",
        "tech_name": "Claudia M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Chad Young",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kevin L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Katherine Mays",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Audrey A.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Nathan Holder",
        "service_type": "Garage Door Insulation",
        "tech_name": "Julie P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Donald Greene",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "John M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Christine Carpenter",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Katie J.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Gregory Adams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cory W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Jonathan Black",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stephen M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Sharon Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joy M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Aaron Moody",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amanda C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Taylor Butler",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ashley B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Patrick Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Thomas M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Caleb West",
        "service_type": "Garage Door Repair",
        "tech_name": "Paige B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Steven Pennington",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Timothy W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Rebecca Clarke",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Felicia Rivera",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lindsay B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Jennifer Fowler",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Zachary T.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Rebecca Rivera",
        "service_type": "Garage Door Services",
        "tech_name": "James S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Amanda Hunter",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Anthony D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Victoria Sullivan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Chase D.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Michael Daniels",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jacob B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Hannah Thompson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Paul Watson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kevin F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Emily Robinson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Edwin L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Nicole Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Cassidy C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Sheila Fischer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Isaiah T.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Pamela Harris",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ryan R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Michael Rich",
        "service_type": "Garage Door Installation",
        "tech_name": "Bailey M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Allen Chambers",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tamara T.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Christopher Jackson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Rachel W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Dennis Booth",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kimberly J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Devon Martin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cameron R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Cody Case",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Luis C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "John Yates",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jodi B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Robert Gonzales",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Jeremy Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tyler G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Samantha Cooper",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Edward A.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Jordan Fields",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stephen D.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.8,
        "customer_name": "Brandon Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mark G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Michael Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jaclyn D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Katelyn Daniels",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brandon P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Brian Cooper",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Andrew Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Taylor M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Diana Carr",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nicholas C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Tyler Kirby",
        "service_type": "Garage Door Opener",
        "tech_name": "Steven R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Isabella Obrien",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sharon C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Timothy Taylor",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rachel B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Charles Allen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Misty L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Tim Solomon",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Ashley Sweeney",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jeffrey R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Alexander May",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Emily M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Jennifer Flores",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Austin S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Olivia Owen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Natalie W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Eric Deleon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Evelyn H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Alexis Jackson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sarah C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Amanda Reyes",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "William M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Caitlyn Neal",
        "service_type": "Garage Door Installation",
        "tech_name": "James B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Gregory Fisher",
        "service_type": "Garage Door Repair",
        "tech_name": "Andrew B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Brittany Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brandy R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "William Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Marilyn P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Tammy Murphy",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kayla W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Natalie Wade",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Thomas R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Linda Barnett",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Rachel Garcia PhD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "William Z.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Bruce Goodwin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anthony F.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "George Ward",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kelsey W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Dawn Morris",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph N.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Tonya Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Daniel S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Stacy Taylor",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jake J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Melissa Hart",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michelle W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Ashley Bell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Richard O.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Lisa Ball",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ashlee C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Joel Perez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Eric F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Christina Miller",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Martha H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Mary Oconnell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Gwendolyn C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Penny Hall",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brandon V.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Travis Downs",
        "service_type": "Garage Door Installation",
        "tech_name": "Gina R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Kenneth Mullen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amy D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Phillip Davis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Adam S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "David Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lauren Z.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Kenneth Lane",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kelly B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Amber Robinson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kristina O.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Edward Holt",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Traci L.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "William Pacheco",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicholas H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Kathleen Allen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Erica W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Phyllis Branch",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Crystal G.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Terry Berg",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Charles H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Christopher Donovan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Loretta R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Ryan Miller",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Corey Koch",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brianna L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Victor Garcia",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Natalie J.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Christine Duke",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Thomas C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Miss Jennifer Webb",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stacey R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Kevin Bell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephen P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Brenda Fuller",
        "service_type": "Garage Door Installation",
        "tech_name": "Ariana D.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Austin Jones MD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brandon N.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Craig Chambers",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Seth J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Travis Lyons",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Natasha G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Nichole Norris",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sherri L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Kara Bailey",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lee C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Eric Burke PhD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Patricia F.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Lindsey Roberson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Traci M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Jason Matthews",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David E.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Amy Moran",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joshua R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Michael Murray",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Heather F.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Shane Jones",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Chad W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Brent Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sabrina J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Alfred Chaney",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tiffany P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Matthew Lee",
        "service_type": "Garage Door Services",
        "tech_name": "Tara C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Alan Stanton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melissa M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Deborah Todd",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Erin T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Gregory Jackson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Angel W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Jennifer Kennedy",
        "service_type": "Garage Door Repair",
        "tech_name": "Jose M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Travis King",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Oscar G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Savannah Pham",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Antonio B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Cynthia Carter",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tyler C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Tony Delgado",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Veronica S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Darrell Herrera",
        "service_type": "Garage Door Off Track",
        "tech_name": "Walter K.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Olivia Massey",
        "service_type": "Garage Door Off Track",
        "tech_name": "Denise M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Emily Greene MD",
        "service_type": "Garage Door Opener",
        "tech_name": "Jerry S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Terry Calderon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mark M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Dustin Shepherd",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alan F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Tracy Parker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Mr. Larry Reyes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jacqueline A.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Anthony Holmes",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Destiny H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Denise Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rebecca L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Vanessa Weeks",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lauren R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Taylor Barnes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Jesse Rosario",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Timothy B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Brian Ross",
        "service_type": "Garage Door Installation",
        "tech_name": "Russell D.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Tammy Strickland",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kimberly F.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Jose Rodriguez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kellie C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Adam Holder",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joseph R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Timothy Tran",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rachel H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Mark Camacho",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Wendy N.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Cynthia Harrison",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeremy R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Timothy Robertson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sean K.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Ariana Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "George G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Michael Lopez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lee B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Jaime Ibarra",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Timothy S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Laura Hall",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Katie S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Michael Kim",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lori R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Kyle Willis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Danielle C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Rachel Fletcher",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kimberly C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "William Miller",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jason W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Karen May",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephen T.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Christopher Malone",
        "service_type": "Garage Door Repair",
        "tech_name": "Melissa C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Austin Morrow",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Edward M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Nicholas Martin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Elaine M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "James Parker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Barbara W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Amanda Heath",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Jason Kerr",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Vernon G.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Veronica Stevens",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cynthia M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Pamela Sutton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Shaun G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Nicholas Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anna G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Edward Hamilton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephanie J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Paul Holland",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nicholas T.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Long",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nicole L.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Antonio Becker",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mark H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Charles Park",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Shelby C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Brittany Conway",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Dwayne Sanders",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Danielle K.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Robert Robinson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Erika V.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "John Thomas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Rachel V.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Wright",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sheena G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Catherine Cunningham MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Danny J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Danielle Franklin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christopher B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Theresa Simmons DVM",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Katherine D.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Jeremiah Moses",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jason R.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Kristopher Young",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Jennifer Evans",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Anita G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Kimberly Harrison",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kimberly M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "George Vasquez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Emily C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Jessica Dorsey",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lindsay P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Mariah Ellison",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeff B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "John Holder",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Charles J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Eugene Carter",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Donna L.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Tommy Martinez",
        "service_type": "Garage Door Installation",
        "tech_name": "Marcus M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Kelly Reynolds",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher E.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Arthur Baker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Natalie N.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Robert Allen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michael C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Jennifer Willis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lynn J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Robert Simmons",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Isaiah W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Scott Ellis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brandon H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Christopher Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ryan D.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Kenneth Cox",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Tyrone Ellison",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Jimmy Ward",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Deborah D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Jason Daniels",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "David Adams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ashley D.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Diana Salinas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "George W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Kevin Long",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Deborah R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Aaron Duarte",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dominique R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Gary Tucker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Erin K.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Gabrielle Evans",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alex C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Carla Lowe",
        "service_type": "Garage Door Services",
        "tech_name": "Brittney O.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Carol Hays",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Theodore J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Holly Miller",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Anna C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Tammy Peck",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jamie G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "William Wright",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Donna C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Jerry Bennett",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeffery Y.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Samuel Lewis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Angela C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Seth Riley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joyce A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Kelly Alvarez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Maria O.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Robin Jensen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ashley C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Todd Ellis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Thomas C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Bradley Dunn",
        "service_type": "Garage Door Repair",
        "tech_name": "Kathleen H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Katherine Hall",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John O.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Chad Marquez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tristan H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Jessica Mcdonald",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Veronica K.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Nancy Mills",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lauren T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Ashley Edwards",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sheryl R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Rebecca Nunez",
        "service_type": "Garage Door Repair",
        "tech_name": "William S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Kayla Whitaker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ronald H.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "John Kelley",
        "service_type": "Garage Door Insulation",
        "tech_name": "Erin B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Cassidy Butler",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Catherine B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Calvin Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Preston A.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Krystal Jones",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Adam C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Mrs. Krista Neal MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anthony W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Felicia Howell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Alison P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Hannah Harris",
        "service_type": "Garage Door Opener",
        "tech_name": "Seth G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Brenda Adams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ryan C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Zoe Whitaker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Heidi C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Megan Rojas",
        "service_type": "Garage Door Repair",
        "tech_name": "Angela M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Louis Lynch",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Thomas P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Michael Walsh",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Elizabeth T.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Patrick Park",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kimberly S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Lori Bruce",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Adam H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Vanessa Wright",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kimberly B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Eric Lindsey",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lance W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Jacob Harrington",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Penny R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "James Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Thomas Rodriguez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John D.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Richard Bryant",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tiffany M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Denise Davis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dana B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Nicole Beasley",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jay W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Rebecca Foster",
        "service_type": "Garage Door Opener",
        "tech_name": "Tina C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Dean Clark",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Adrienne H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Andrea Ford",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Heather L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Jose Aguilar",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jerry A.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Courtney Wade",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Denise L.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Gregory Kelly",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Heidi H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Brian Moran",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joshua C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Eric Hall",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brian M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Timothy Deleon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Steven S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Ethan Stephens",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Katherine O.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Joshua Lynch",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kimberly H.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "David Kelly",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kimberly H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Amy Schroeder",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lauren A.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Michael Abbott",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Angela B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Kenneth Young",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Karen B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Amanda Campbell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Eugene J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "William Campbell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicholas M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Mr. Mark Adams MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mckenzie M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Jackie Cunningham",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gary W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Michael Hess",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brian M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Kimberly Obrien",
        "service_type": "Garage Door Insulation",
        "tech_name": "Erica Y.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Seth Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Sean Holland",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tracy W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Corey Clark",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "William P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "James Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alice R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Joshua Curtis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jonathan A.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Francisco Grant",
        "service_type": "Garage Door Installation",
        "tech_name": "Cameron P.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Michael Wilson",
        "service_type": "Garage Door Services",
        "tech_name": "Brent T.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Robert Orozco",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kevin D.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Justin Maxwell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melissa M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "James Hodge",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Kara Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Perry M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Thomas Gomez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Donald G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Kyle Bryan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stephanie R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Mrs. Angela Lopez MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gabriella C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Mrs. Kathleen Wilson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jenna M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Chelsea Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Susan J.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Theresa Kim",
        "service_type": "Garage Door Services",
        "tech_name": "Hailey G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Sara Oconnell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Eric M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Robert Bass",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda K.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Ashley Vasquez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Laura C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Teresa Adams MD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Luis C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "William Dudley",
        "service_type": "Garage Door Opener",
        "tech_name": "Nancy B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "David Wright",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brett B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Thomas Hernandez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amber M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Melissa Oneill",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica A.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Ann Kennedy",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Danny Stevenson",
        "service_type": "Garage Door Repair",
        "tech_name": "Adam P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Brandon Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rhonda V.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Corey Campbell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Caleb Wells",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eric C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Mr. Carl Zavala PhD",
        "service_type": "Garage Door Installation",
        "tech_name": "Kimberly R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Samuel Ponce",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "April C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Marcus Edwards",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cindy R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Dawn Sanchez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dalton S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Susan Hernandez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jonathan J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Gregory Evans",
        "service_type": "Garage Door Off Track",
        "tech_name": "Antonio R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Kathleen Mcgee",
        "service_type": "Garage Door Installation",
        "tech_name": "Renee M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Melissa Gonzalez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert E.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Allen Orozco",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Vincent L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Michael Zimmerman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gerald P.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Corey Perez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nicholas S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Caitlin Brown",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Yolanda W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Jason Erickson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jessica H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Jon Decker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brett P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Stacy Humphrey",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Carolyn Lee",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ashley P.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Samantha Sexton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Stephanie W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Edward Golden",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "William Wright",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Walter Krueger",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Patricia H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Holly Kelly",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Rachael Garcia",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stephen K.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Megan White",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kathleen F.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Kelsey Baker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kathryn K.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Mitchell Peters",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kathryn F.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Crystal Preston",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Bradley Sosa",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melissa H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "John Hester",
        "service_type": "Garage Door Opener",
        "tech_name": "Walter P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Robert Sanchez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stephen J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Keith Cameron",
        "service_type": "Garage Door Off Track",
        "tech_name": "Daniel B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Leah Gallagher",
        "service_type": "Custom Garage Door Design",
        "tech_name": "William M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Gina Brooks",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Dean A.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Jon Brennan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jamie S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Mario Gregory",
        "service_type": "Garage Door Services",
        "tech_name": "Norman J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Brandon Garcia II",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Erin Espinoza",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joseph F.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Clinton Dawson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Bridget G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Christopher Ferguson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shawna T.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Travis Collins",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Lindsay Boone",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kelly R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Gary Riddle",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ricky K.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Kenneth Garrison",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sharon P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Tyler Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Erica S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Mrs. Mary Rodriguez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Cindy B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Cathy Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Elizabeth W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Adam Ward",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Theresa L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Dylan Cooper",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Curtis J.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "April Collins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Paige W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Dr. Ryan Craig",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jason J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Brendan Roberts",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ashley M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Ricardo Payne",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Frank L.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Frederick White",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David J.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Ryan Warren",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Julia M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Erik Rogers",
        "service_type": "Garage Door Off Track",
        "tech_name": "Julia S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Heidi Hamilton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Timothy R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Jessica Anderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Christina E.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Donna Casey",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Michael Watson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Allison R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Melvin Castro",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "James R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Danielle Orozco",
        "service_type": "Garage Door Repair",
        "tech_name": "Christopher D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Jessica Espinoza",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Catherine R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Michelle Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Mr. Gabriel James",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ian C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "James Barker",
        "service_type": "Garage Door Installation",
        "tech_name": "Tammie S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Jesse Carroll",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard F.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Victor Lewis",
        "service_type": "Garage Door Repair",
        "tech_name": "Shannon R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Kathleen Moore",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Angela C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "William Parks",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kevin M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Jill Hall",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael A.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Charles Hernandez MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tiffany C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Robert Watson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joshua N.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Paul Moore",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Shannon J.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Danielle Hayes",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kristin H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Joseph Fuller",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ronald S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Barbara Baxter",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Maureen W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Michael Sullivan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mark M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Barbara Brock",
        "service_type": "Garage Door Services",
        "tech_name": "Joshua L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Misty Barrett",
        "service_type": "Garage Door Installation",
        "tech_name": "Emily C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Lauren Perry",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephen H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Donna Palmer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Charlotte D.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Max Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jacob N.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Mr. Brad Green",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Paige D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Teresa Harris",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Patrick M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Dr. Mark Velez Jr.",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Dr. Jose Ramsey",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nathan S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Christopher Moore",
        "service_type": "Garage Door Services",
        "tech_name": "Jeffrey J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Gabriel Lang",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Kim",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Breanna R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Laura Butler",
        "service_type": "Garage Door Off Track",
        "tech_name": "Walter L.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Angela Reese",
        "service_type": "Garage Door Repair",
        "tech_name": "James S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Alan Mitchell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cheryl J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Adrienne Richardson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sandra P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Ethan Noble",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa V.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Alan Graham",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Justin Andersen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Hannah B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Donald Stewart",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Willie N.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Garrett Davis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Chad B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Angela Summers",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nicholas M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Kristina Hopkins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dustin M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "James Hoover",
        "service_type": "Garage Door Off Track",
        "tech_name": "Angela F.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "David Lee",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Carolyn V.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Ian Hoover",
        "service_type": "Garage Door Opener",
        "tech_name": "Candace N.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Wayne Moore",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kelly J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "David Wise",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Paul B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Daniel Ware",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeremy M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Michele Turner",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Laura P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
